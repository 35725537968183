<template>
  <div class="Matchcenter">
    <Header
      back="返回"
      title="匹配工具"
      index="首页"
      titleOne="智能匹配"
      beforeTitle="匹配工具"
    />
    <div class="content">
      <h1>苏州一品中众创投资管理有限公司的匹配结果</h1>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="政策名称" width="150">
        </el-table-column>
        <el-table-column prop="time" label="匹配时间"> </el-table-column>

        <el-table-column prop="progress" label="匹配进度" width="200">
          <template slot-scope="scope">
            <el-progress
              type="line"
              :stroke-width="8"
              :percentage="scope.row.progress"
            ></el-progress>
          </template>
        </el-table-column>

        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button
              class="edit"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        >
        </el-pagination>
      </div>
      <div class="foot_TJ">
        <el-button type="primary" @click="onSubmit">保存结果</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage3: 5,
      tableData: [
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
          type: "意向客户",
          progress: 8,
        },
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
        },
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
        },
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      input: "",
    };
  },

  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleEdit() {},
    handleDelete(index, row) {
      console.log(index, row);
    },
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style>
.el-progress__text {
  color: #0f38ff;
  font-weight: bold;
  font-size: 14px;
}
</style>
